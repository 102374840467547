export const leagues = [
    {
        name: 'Premier League',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/47.png',
        id: '1'
    },
    {
        name: 'LaLiga',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/87.png',
        id: '2'
    },
    {
        name: 'Bundesliga',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/54.png',
        id: '3'
    },
    {
        name: 'Serie A',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/55.png',
        id: '4'
    },
    {
        name: 'Ligue 1',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/53.png',
        id: '5'
    },
    {
        name: 'Eredivisie',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/57.png',
        id: '6'
    },
    {
        name: 'Champions League',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/42.png',
        id: '7'
    },
    {
        name: 'Europa League',
        img: 'https://images.fotmob.com/image_resources/logo/leaguelogo/dark/73.png',
        id: '8'
    },
]