import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)


const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<App/>}/>))

root.render(
    <RouterProvider router={router}/>
)

reportWebVitals()