import React from 'react'

const Footer = () => {
    return (
        <footer className="bg-white rounded-lg shadow dark:bg-zinc-900 mt-32">
            <div className="w-full max-w-screen-xl mx-auto md:py-3">
                <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a
                    href="hello.com" className="hover:underline">Football™</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer