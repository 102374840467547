import React from 'react'
import {leagues} from "../../../core/constants/constants";

const Leagues = () => {
    return (
        <div
            className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-zinc-900 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Football Leagues</h5>
            </div>
            <div className="flow-root">
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {leagues.map(el => {
                        return (
                            <li className="py-3 sm:py-4" key={el.id}>
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="w-8 h-8 rounded-full"
                                            src={el.img}
                                            alt={el.name}
                                        />
                                    </div>
                                    <div className="flex-1 min-w-0 ms-4">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {el.name}
                                        </p>
                                    </div>

                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Leagues