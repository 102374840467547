import React from 'react'

const Scoreboard = () => {
    return (
        <div
            className="p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-zinc-900 dark:border-gray-700"
            style={{minWidth: 1000, minHeight: 800}} //todo temporary
        >
            <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Games</h5>
            </div>
        </div>
    )
}

export default Scoreboard