import {Routes, Route} from "react-router-dom"
import Layout from "./pages/Layout"
import Main from "./pages/Main"
import NoPage from "./pages/NoPage"
import React from 'react'
import Footer from "./components/Footer";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Main/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>
            <Footer/>
        </>
    )
}

export default App
