import React from "react";

interface UseButtonEvents {
    handleModalClose: (setClose: React.Dispatch<React.SetStateAction<boolean>>) => void
}

export const useButtonEvents = (): UseButtonEvents  => {

    const handleModalClose = (setClose: React.Dispatch<React.SetStateAction<boolean>>) => {
        const outsideClick = (e: MouseEvent): void => {
            if (!(e.target as HTMLInputElement).closest('#login-modal')) setClose(false)
        }
        const escButtonClick = (e: KeyboardEvent): void => {
            if (e.key === 'Escape') setClose(false)
        }
        document.body.addEventListener('click', outsideClick, true)
        document.body.addEventListener('keydown', escButtonClick)
        return () => {
            document.body.removeEventListener('click', outsideClick)
        }
    }

    return {
        handleModalClose
    }
}