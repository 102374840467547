import React from 'react'
import Leagues from './leagues/views/Leagues'
import Scoreboard from "./scoreboard/views/Scoreboard";

const Main = () => {
    return (
        <main className='flex justify-center items-center mt-32'>
            <div className='w-full flex justify-center'>
                <section className='mr-6'>
                    <Leagues/>
                </section>
                <section className='ml-6'>
                    <Scoreboard/>
                </section>
            </div>
        </main>
    )
}

export default Main