import React, {ChangeEventHandler} from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip"

interface TextFieldProps {
    id: string
    name: string
    placeholder: string
    type?: string
    handleChange: ChangeEventHandler<HTMLInputElement>
    value?: string | null
    className?: string
    required?: boolean
    error?: string
}

const TextField = (props: TextFieldProps) => {
    return (
        <div className={`${props.className ? props.className : ''}`}>
            <div>
                <label
                    className={`block text-white text-sm font-bold mb-2 ${props.required ? 'required' : ''}`}
                    htmlFor={props.id}
                >
                    {props.name}
                </label>
                <input
                    className={`shadow appearance-none ${props.error ? 'border-r-8 border-red-800' : 'border'} rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id={props.id}
                    data-tooltip-id={props.id}
                    value={props.value || ''}
                    onChange={props.handleChange}
                    type={props.type ? props.type : 'text'}
                    placeholder={props.placeholder}
                />
            </div>
            {props.error ?
                <ReactTooltip
                    id={props.id}
                    place='top'
                    variant='error'
                    content={props.error}
                />
                : null}
        </div>
    )
}

export default TextField