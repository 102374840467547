import {LoginFormValidations, LoginFormValues} from "../types";
import {required} from "../../../core/helpers/validations";

export const LoginFormValidation = (values: LoginFormValues): LoginFormValidations => {
    const formErrors: LoginFormValidations = {
        userName: required(values.userName),
        email: values.register ? required(values.email) : undefined,
        password: required(values.password),
        firsName: values.register ? required(values.firstName) : undefined,
        lastName: values.register ? required(values.lastName) : undefined,
    }
    const errors: LoginFormValidations = {} as LoginFormValidations
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value) errors[key as keyof LoginFormValidations] = value
    })
    return errors
}