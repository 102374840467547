import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import {LoginForm} from '../types'
import {LoginFormValidation} from "../validates/loginForm";
import TextField from "../../../components/fields/TextField";
import {useLogin} from "../hooks/useLogin";
import {useButtonEvents} from "../../../hooks/useButtonEvents";


interface LoginProps {
    openLogin: boolean
    setOpenLogin: React.Dispatch<React.SetStateAction<boolean>>
}

const Login = (props: LoginProps) => {
    const buttonEventHook = useButtonEvents()

    const form: LoginForm = useFormik({
        initialValues: {userName: '', email: '', password: '', register: false, firstName: '', lastName: ''},
        enableReinitialize: true,
        validateOnChange: false,
        validate: LoginFormValidation,
        onSubmit: async () => ({})
    })

    useEffect(() => {
        buttonEventHook.handleModalClose(props.setOpenLogin)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hook = useLogin()
    return (
        <div id="default-modal" style={{display: 'flex'}} tabIndex={-1}
             className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-xl max-h-full">
                <div className="bg-white rounded-lg border border-white dark:bg-zinc-900" id='login-modal'>
                    <div
                        className="flex items-center justify-between p-4 md:p-5 dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Log In
                        </h3>
                        <span onClick={() => props.setOpenLogin(false)} className={'cursor-pointer'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                                    </svg>
                                </span>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <form onSubmit={form.handleSubmit}>
                            {form.values.register ?
                                <>
                                    <div className='flex mb-4'>
                                        <TextField
                                            id='firstName'
                                            handleChange={form.handleChange}
                                            name='First name'
                                            placeholder='First Name'
                                            className='mr-3 w-1/2'
                                            value={form.values.firstName}
                                            required
                                            error={form.errors.firsName}
                                        />
                                        <TextField
                                            id='lastName'
                                            handleChange={form.handleChange}
                                            name='Last name'
                                            placeholder='Last Name'
                                            value={form.values.lastName}
                                            error={form.errors.lastName}
                                            className='w-1/2'
                                            required
                                        />
                                    </div>
                                    <TextField
                                        id='email'
                                        name='Email'
                                        handleChange={form.handleChange}
                                        placeholder='Email'
                                        value={form.values.email}
                                        type='email'
                                        className='mb-4'
                                        required
                                        error={form.errors.email}
                                    />
                                </>
                                : null}
                            <TextField
                                id='userName'
                                handleChange={form.handleChange}
                                name='Username'
                                placeholder='Username'
                                value={form.values.userName}
                                error={form.errors.userName}
                                className='mb-4'
                                required
                            />
                            <TextField
                                id='password'
                                handleChange={form.handleChange}
                                name='Password'
                                placeholder='Password'
                                type='password'
                                value={form.values.password}
                                error={form.errors.password}
                                className='mb-4'
                                required
                            />
                            <div className='flex justify-end mt-6'>
                                    <span
                                        className="inline-block font-bold text-sm text-white hover:text-gray-600 cursor-pointer"
                                        onClick={() => form.setFieldValue('register', !form.values.register)}
                                    >
                                        {form.values.register ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
                                    </span>
                            </div>
                            <div
                                className="flex md:py-5 rounded-b dark:border-gray-600">
                                <button
                                    className='bg-white text-zinc-900 hover:text-white hover:bg-zinc-900 py-1.5 px-3 rounded border border-white'
                                    type='submit'
                                    onClick={async () => {
                                        await hook.tempRequest()
                                    }}
                                >
                                    {form.values.register ? 'Sign Up' : 'Log In'}
                                </button>
                                <button
                                    className='bg-zinc-900 text-white hover:text-zinc-900 hover:bg-white py-1.5 px-3 rounded border border-white ml-3'
                                    type='button'
                                    onClick={() => {
                                        props.setOpenLogin(false)
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login