import {GlobalResponse, ResponseStatuses} from "./types/apiGlobalTypes";
import {stringify} from "../core/helpers/queryString";
import {getErrorMessages} from "./helper";

const baseURL = process.env.REACT_APP_API_BASE_URL

const privateRequest = async (url: string, method: string, params: unknown, BodyInit?: BodyInit) => {
    try {
        const body = BodyInit
        const urlParams = stringify(params, {encode: false})
        const response = await fetch(baseURL + url + urlParams, {
            headers: {
                Accept: 'application/json',
                'Content-Type': "application/json"
            },
            method,
            body
        })

        if (!response.ok) {
            return {
                status: response.status,
                content: null,
                errors: await getErrorMessages(response)
            }
        }
        const content = await response.json()
        return {
            status: response.status,
            content: content,
            errors: null
        }
    } catch (error) {
        console.error(error)
        return {
            content: null,
            status: ResponseStatuses.UNEXPECTED,
            errors: [{name: 'catch', message: 'unexpected'}]
        }
    }
}


export const post = (url: string, body: BodyInit): Promise<GlobalResponse> => {
    return privateRequest(url, 'POST', {} , body as BodyInit)
}

export const patch = (url: string, body: BodyInit): Promise<GlobalResponse> => {
    return privateRequest(url, 'PATCH', {}, body)
}

export const get = (url: string, params: unknown = {} ): Promise<GlobalResponse> => {
    return privateRequest(url, 'GET', params)
}

export const deleteItem = (url: string, params?: unknown): Promise<GlobalResponse> => {
    return privateRequest(url, 'DELETE', {}, params as BodyInit)
}

export const put = (url: string, body: BodyInit): Promise<GlobalResponse> => {
    return privateRequest(url, 'PUT', {}, body)
}